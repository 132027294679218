(function () {
	'use strict';

	angular
		.module('common')
		.factory('seedcodeCalendar', ['$rootScope', seedcodeCalendar]);

	function seedcodeCalendar($rootScope) {
		//calendar object items are config, statuses, element, schedules, defaults, date

		/* DEBUG */
		var dev = {};
		dev.speedTest = false;
		/* END DEBUG */
		var calendar = {};

		const timeDurations = [
			{id: '00:05:00', label: '5 min'},
			{id: '00:10:00', label: '10 min'},
			{id: '00:15:00', label: '15 min'},
			{id: '00:15:00', label: '20 min'},
			{id: '00:30:00', label: '30 min'},
			{id: '01:00:00', label: '60 min'},
		];

		//Maps configuration to  user settings and user settings to config and assigns default values
		calendar.userConfigMap = {
			primaryCalendar: {
				//An object containing the id's of available source types. {filemakerServer {id: 2}, googleCalendar {id: 3}}
				setting: 'primaryCalendar',
				defaultValue: '',
			},
		};

		//Maps configuration to under the hood settings and under the hood settings to config and assigns default values
		calendar.stateConfigMap = {
			showMeasure: {
				setting: 'showMeasure',
				saved: 'showMeasure',
				defaultValue: {status: false},
			},
			viewSettings: {
				setting: 'viewSettings',
				saved: 'viewSettings',
				defaultValue: {
					defaults: {
						resourceColumns: '3',
						resourceDays: 4,
						resourcePosition: '0',
						weekCount: 1,
					},
				},
			},
		};

		//Maps configuration to settings and settings to config and assigns default values
		calendar.configMap = {
			linkedUserToken: {
				//An object containing the id's of available source types. {filemakerServer {id: 2}, googleCalendar {id: 3}}
				setting: 'linkedUserToken',
				visible: false,
			},
			deviceID: {
				//A unique identifier for the device (computer)
				setting: 'deviceID',
				visible: false,
			},
			sourceTypes: {
				//An object containing the id's of available source types. {filemakerServer {id: 2}, googleCalendar {id: 3}}
				setting: 'sourceTypes',
				defaultValue: {},
				visible: false,
			},
			primaryCalendarOverride: {
				setting: 'primaryCalendarOverride',
				defaultValue: '',
				visible: false,
			},
			compressedView: {
				setting: 'compressedView',
				displayValue: 'Compressed view',
				saved: 'compressedView',
				defaultValue: false,
				visible: true,
				format: 'yesno',
				category: 'Views',
				helptext:
					"This option applies to the views where times don't show, notably the Month view and the \"List\" versions of the Day, Week, and Resoure views. When these views are compressed DayBack will only show one line for each event. When the view is not compressed the event will expand vertically to show as much text as is there. This means that on the Month view in particular the uncompressed view means you'll need to scroll to see the whole month, but you'll see more information about each event without having to click on it.",
			},
			lazyFetching: {
				setting: 'lazyFetching',
				displayValue: 'Fetch events only when date range expands',
				defaultValue: true,
				visible: true,
				format: 'yesno',
				category: 'Misc',
				helptext:
					'With this set to "yes" the calendar will perform faster as DayBack will ask the server for new events less often. If you\'re on Week view and click on a day within that week, DayBack already has the events for that day and thus won\'t fetch them again until you navigate to a new week or hit the "refresh" button.',
			},
			snapToToday: {
				setting: 'snapToToday',
				displayValue: 'Snap to today each day',
				defaultValue: true,
				visible: true,
				format: 'yesno',
				category: 'Misc',
				helptext:
					'With this set to "yes" the calendar will default the focus date to the current date when loading.',
			},
			// // Pulled as we use the Date Format in Soure Settings to determine how to write back to the file. Believe this one was redundant.
			// dateFormat: {
			//   setting: "dateFormat",
			//   displayValue: 'Date format',
			//   defaultValue: "MM/DD/YYYY",
			//   category: 'Date / Time Formats',
			//   helptext: 'What do we use this for?',
			// },
			dateStringFormat: {
				setting: 'dateStringFormat',
				displayValue: 'Date format: long',
				defaultValue: 'auto', //'dddd, MMM D, YYYY'
				visible: true,
				category: 'Date / Time Formats',
				helptext:
					'This is used to format the date at the top of the Day view and anywhere else there is a lot of length available. Formmating options are based on moment.js and allow things like this...<br>' +
					'<table class="exampleCode">' +
					'<tr><td>dd</td><td>Su Mo ... Fr Sa</td></tr>' +
					'<tr><td>ddd</td><td>Sun Mon ... Fri Sat</td></tr>' +
					'<tr><td>dddd</td><td>Sunday Monday ... Friday Saturday</td></tr>' +
					'<tr><td>MM</td><td>01 02 ... 11 12</td></tr>' +
					'<tr><td>MMM</td><td>Jan Feb ... Nov Dec</td></tr>' +
					'<tr><td>MMMM</td><td>January February ... November December</td></tr>' +
					'</table><br>' +
					'...for a full description of the date grammar available here, see the <a href="http://momentjs.com/docs/#/displaying/" target="blank">moment.js display docs</a>. Translation is taken care of automatically and doesn\'t need to be done here.',
			},
			dateStringShortFormat: {
				setting: 'dateStringShortFormat',
				displayValue: 'Date format: short',
				defaultValue: 'auto', //'ddd, MMM D, YYYY'
				visible: true,
				category: 'Date / Time Formats',
				helptext:
					'This formats the date in the event popover and other places where there is not a lot of length avaialble. Formmating options are based on moment.js and allow things like this...<br>' +
					'<table class="exampleCode">' +
					'<tr><td>dd</td><td>Su Mo ... Fr Sa</td></tr>' +
					'<tr><td>ddd</td><td>Sun Mon ... Fri Sat</td></tr>' +
					'<tr><td>dddd</td><td>Sunday Monday ... Friday Saturday</td></tr>' +
					'<tr><td>MM</td><td>01 02 ... 11 12</td></tr>' +
					'<tr><td>MMM</td><td>Jan Feb ... Nov Dec</td></tr>' +
					'<tr><td>MMMM</td><td>January February ... November December</td></tr>' +
					'</table><br>' +
					'...for a full description of the date grammar available here, see the <a href="http://momentjs.com/docs/#/displaying/" target="blank">moment.js display docs</a>. Translation is taken care of automatically and doesn\'t need to be done here.',
			},
			timeFormat: {
				setting: 'timeFormat',
				displayValue: 'Time format',
				defaultValue: 'auto',
				visible: true,
				category: 'Date / Time Formats',
				helptext:
					'Formats the time shown in the event popover and on the time scale in DayBack\'s "schedule" views. This is also where you can specify 24 hour or 12 hour time (capital "H" for 24 hours time, lowercase for 12 hours). Formmating options are based on moment.js and allow things like this...<br>' +
					'<table class="exampleCode">' +
					'<tr><td>A</td><td>AM PM</td></tr>' +
					'<tr><td>a</td><td>am pm</td></tr>' +
					'<tr><td>H</td><td>0 1 ... 22 23</td></tr>' +
					'<tr><td>HH</td><td>00 01 ... 22 23</td></tr>' +
					'<tr><td>h</td><td>01 02 ... 11 12</td></tr>' +
					'</table><br>' +
					'...for a full description of the date grammar available here, see the <a href="http://momentjs.com/docs/#/displaying/" target="blank">moment.js display docs</a>.',
			},
			showAdminSettings: {
				setting: 'showAdminSettings',
				displayValue: 'Show admin settings button',
				defaultValue: true,
				visible: false,
				format: 'yesno',
				category: 'Views',
				helptext:
					'Show or withold the button that takes users to this admin screen.',
			},
			showSidebar: {
				setting: 'showSidebar',
				displayValue: 'Show sidebar',
				saved: 'showSidebar',
				defaultValue: true,
				visible: true,
				format: 'yesno',
				category: 'Views',
				helptext:
					'Set to "Yes" the left-hand side bar will be showing by default, otherwise it will be hidden by default. Individual users can change their sidebar\'s visibilty (unless "lock sidebar" is set to "Yes" below) and DayBack will remember that for them.',
			},
			lockSidebar: {
				setting: 'lockSidebar',
				displayValue: 'Lock sidebar',
				defaultValue: false,
				visible: true,
				format: 'yesno',
				category: 'Views',
				helptext:
					'When set to "Yes" the user is prevented from changing the default visibility of the left-hand sidebar (that initial visibility is set in the setting above.',
			},
			defaultEventColor: {
				setting: 'defaultEventColor',
				displayValue: 'Default color',
				defaultValue: 'rgba(244, 244, 244, 0.85)',
				visible: true,
				category: 'Event Styles',
				helptext:
					'The status color to used for newly created events or events with no status. We reccomend a little transparency here and rgba colors are supported where the last value is the transparency (i.e. in this color...<br><br>' +
					'<div class="exampleCode">rgba(244, 244, 244, 0.85)</div><br>' +
					'...0.85 is the transparency on a scale of 0 to 1, meaning it is nearly opaque).',
			},
			defaultBorderColor: {
				setting: 'defaultBorderColor',
				displayValue: 'Default border color',
				defaultValue: 'rgb(190,190,190)',
				visible: true,
				category: 'Event Styles',
				helptext: 'The color of the thin line around events.',
			},
			defaultTextColor: {
				setting: 'defaultTextColor',
				displayValue: 'Default text color',
				defaultValue: 'rgb(25,25,25)',
				visible: true,
				category: 'Event Styles',
				helptext: 'The color for the text of an event.',
			},
			defaultLightBorderColor: {
				setting: 'defaultLightBorderColor',
				displayValue: 'Default light border color',
				defaultValue: 'rgb(25,25,25)',
				visible: true,
				category: 'Event Styles',
				helptext:
					"When an event's status color is very dark DayBack switches to a border color that shows up more easily.",
			},
			defaultLightTextColor: {
				setting: 'defaultLightTextColor',
				displayValue: 'Default light text color',
				defaultValue: 'rgb(255,255,255)',
				visible: true,
				category: 'Event Styles',
				helptext:
					"When an event's status color is very dark DayBack sitches to a text color that is lighter and easier to read.",
			},
			defaultTimedEventDuration: {
				setting: 'defaultTimedEventDuration',
				displayValue: 'Default duration for timed events',
				defaultValue: '01:00:00',
				visible: true,
				category: 'Time Scales',
				format: 'select',
				options: timeDurations,
				helptext:
					'When only a start time is specified, this will be the duration of newly created events until they are edited further.',
			},
			fluidMonths: {
				setting: 'fluidMonths',
				displayValue: 'Fluid months',
				saved: 'fluidMonths',
				defaultValue: true,
				visible: true,
				format: 'yesno',
				category: 'Views',
				helptext:
					'When set to "Yes", DayBack will show every event for each day of the month, making some weeks "taller" than others. The whole month may not fit on screen without scrolling. When set to "No" the entire month will be visible on screen without scrolling and events that don\'t fit in the space alloted for each day will be indicated by "X more" where X is the number of events there wasn\'t space to render.',
			},
			snapToMonth: {
				setting: 'snapToMonth',
				displayValue: 'Snap Daily View To Month',
				saved: 'snapToMonth',
				defaultValue: true,
				visible: true,
				views: ['basicResourceDays', 'agendaDays'],
				format: 'yesno',
				category: 'Views',
				helptext:
					'Forces the resource daily view to navigate in months rather than a set number of days.',
			},
			weekends: {
				setting: 'weekends',
				displayValue: 'Show weekends',
				saved: 'weekends',
				defaultValue: true,
				visible: true,
				format: 'yesno',
				category: 'Views',
			},
			distances: {
				setting: 'distances',
				displayValue: 'Show distance lines',
				saved: 'distances',
				defaultValue: true,
				visible: true,
				format: 'yesno',
				category: 'Views',
			},
			horizonBreakoutField: {
				setting: 'horizonBreakoutField',
				displayValue: 'Breakout horizon view by',
				saved: 'horizonBreakoutField',
				defaultValue: false,
				visible: true,
				format: 'select',
				options: [
					{id: false, label: 'nothing'},
					{id: 'resource', label: 'Resource'},
					{id: 'status', label: 'Status'},
				],
				category: 'Views',
				helptext:
					'Setting this will break out events in horizon view by the item chosen. For example choosing "Resource" will group events by resource.',
				mutate: function (data) {
					//Normalize data
					if (data) {
						data = data.toLowerCase();
					}
					if (data !== 'resource' && data !== 'status') {
						return false;
					} else {
						return data;
					}
				},
			},
			breakout: {
				setting: 'breakout',
				displayValue: 'Breakout horizon view by',
				saved: 'breakout',
				defaultValue: false,
				visible: false,
				json: true,
				// format: 'select',
				// options: [{id: false, label: 'nothing'}, {id: 'resource', label: 'Resource'}, {id: 'status', label: 'Status'}],
				category: 'Views',
				helptext:
					'Setting this will break out events in horizon view by the item chosen. For example choosing "Resource" will group events by resource.',
				//mutate: function(data) {}
			},
			hideSalesforceRepetitions: {
				setting: 'hideSalesforceRepetitions',
				displayValue: 'Hide Repeating Salesforce Events',
				saved: 'hideSalesforceRepetitions',
				defaultValue: true,
				visible: false,
				format: 'yesno',
				category: 'Views',
				helptext:
					'This allows you to specify if the calendar should hide repeating events from Salesforce.',
			},
			automaticRepetitions: {
				setting: 'automaticRepetitions',
				displayValue: 'Automatically Hide Repeating Google Events',
				saved: 'automaticRepetitions',
				defaultValue: true,
				visible: false,
				format: 'yesno',
				category: 'Views',
				helptext:
					'Setting this will let DayBack automatically detmine when to display repeting events.',
			},
			hideDailyRepetitions: {
				setting: 'hideDailyRepetitions',
				displayValue: 'Hide Daily Repeating Google Events',
				saved: 'hideDailyRepetitions',
				defaultValue: false,
				visible: false,
				format: 'yesno',
				category: 'Views',
				helptext:
					'Setting this will let DayBack automatically detmine when to display repeting events.',
			},
			hideWeeklyRepetitions: {
				setting: 'hideWeeklyRepetitions',
				displayValue: 'Hide Weekly Repeating Google Events',
				saved: 'hideWeeklyRepetitions',
				defaultValue: false,
				visible: false,
				format: 'yesno',
				category: 'Views',
				helptext:
					'Setting this will let DayBack automatically detmine when to display repeting events.',
			},
			hideMonthlyRepetitions: {
				setting: 'hideMonthlyRepetitions',
				displayValue: 'Hide Monthly Repeating Google Events',
				saved: 'hideMonthlyRepetitions',
				defaultValue: false,
				visible: false,
				format: 'yesno',
				category: 'Views',
				helptext:
					'Setting this will let DayBack automatically detmine when to display repeting events.',
			},
			hideYearlyRepetitions: {
				setting: 'hideYearlyRepetitions',
				displayValue: 'Hide Yearly Repeating Google Events',
				saved: 'hideYearlyRepetitions',
				defaultValue: false,
				visible: false,
				format: 'yesno',
				category: 'Views',
				helptext:
					'Setting this will let DayBack automatically detmine when to display repeting events.',
			},
			measureAggregate: {
				setting: 'measureAggregate',
				displayValue: 'Chart by day, week...',
				saved: 'measureAggregate',
				defaultValue: 'auto',
				visible: true,
				format: 'select',
				options: [
					{id: 'auto', label: 'Auto'},
					{id: 'day', label: 'Day'},
					{id: 'week', label: 'Week'},
					{id: 'month', label: 'Month'},
				],
				category: 'Analytics',
				helptext:
					'The duration to aggregate chart data. For example "Week" would mean chart data is added for the whole week and displayed as one data point for that week.<br><br> <ul style: "margin-left: 20px"><li>auto: Will determine the setting automatically based on how many days are being viewed.</li><li>Day: Will aggregate data for each day.</li><li>Week: Will aggregate data each week being displayed and the rendered data point will represent that full week.</li><li>Month: Will aggregate data for each calendar month and will render one data point per month.</li></ul><br><a href="https://docs.dayback.com/article/139-analytics#data" target="_blank">Learn More</a>',
			},
			measureCombineResources: {
				setting: 'measureCombineResources',
				displayValue: 'Combine Resources',
				saved: 'measureCombineResources',
				defaultValue: false,
				visible: true,
				format: 'yesno',
				category: 'Analytics',
				helptext:
					'Combine the resources in Pivot List view into a single curve.</li></ul><br><a href="https://docs.dayback.com/article/139-analytics#data" target="_blank">Learn More</a>',
			},
			showMeasureThreshold: {
				setting: 'showMeasureThreshold',
				displayValue: 'Show Threshold Line On Chart',
				saved: 'showMeasureThreshold',
				defaultValue: false,
				visible: true,
				format: 'yesno',
				category: 'Analytics',
				helptext:
					'Show a threshold for measuring. Anything above the threshold will appear differenly on the chart. <br><a href="https://docs.dayback.com/article/139-analytics#threshold" target="_blank">Learn More</a>',
			},
			measureThreshold: {
				setting: 'measureThreshold',
				displayValue: 'Threshold Value',
				saved: 'measureThreshold',
				defaultValue: '',
				visible: true,
				category: 'Analytics',
				helptext:
					'A threshold value for measuring. Anything above the threshold will appear differenly on the chart.',
			},
			measureThresholdLineColor: {
				setting: 'measureThresholdLineColor',
				displayValue: 'Threshold Line Color',
				saved: 'measureThresholdLineColor',
				defaultValue: 'orange',
				visible: true,
				category: 'Analytics',
				helptext:
					'The color of the line separating measure data over the set threshold. Use any CSS friendly color, like "orange" or use an RGB value like this <br><br> <div class="exampleCode">rgb(124, 26, 244)</div><br>',
			},
			measureThresholdFillColor: {
				setting: 'measureThresholdFillColor',
				displayValue: 'Threshold Fill Color',
				saved: 'measureThresholdFillColor',
				defaultValue: 'yellow',
				visible: true,
				category: 'Analytics',
				helptext:
					'The color of the area above the threshold line. Use any CSS friendly color, like "yellow" or use an RGB value like this <br><br> <div class="exampleCode">rgb(124, 26, 244)</div><br>',
			},
			measureThresholdFillOpacity: {
				setting: 'measureThresholdFillOpacity',
				displayValue: 'Threshold Fill Opacity',
				saved: 'measureThresholdFillOpacity',
				defaultValue: '0.25',
				visible: true,
				category: 'Analytics',
				helptext:
					'The opacity of the area above the threshold line. Values below 0.3 work best for most colors.',
			},
			measureType: {
				setting: 'measureType',
				displayValue: 'Chart Data Type',
				saved: 'measureType',
				defaultValue: 'count',
				visible: true,
				format: 'select',
				options: [
					{id: 'count', label: 'Count'},
					{id: 'duration', label: 'Duration'},
					{id: 'field', label: 'Field'},
				],
				category: 'Analytics',
				helptext:
					'The type of data to measure in the chart.<br><br> <ul style: "margin-left: 20px"><li>Count: chart the number of items: it will count how many events you have each day, week, etc.</li><li>Duration: add up the duration of the visible items</li><li>Field: chart the values in any custom field added to DayBack from your FileMaker table or Salesforce object.</li></ul><br><a href="https://docs.dayback.com/article/139-analytics#data" target="_blank">Learn More</a>',
			},
			measureField: {
				setting: 'measureField',
				displayValue: 'Specific Field To Chart',
				saved: 'measureField',
				defaultValue: '',
				visible: true,
				category: 'Analytics',
				helptext:
					'If you selected " field " above, this is the name of the field you\'ll be measuring. Enter the label of your custom field. "Title" and "Description" are also supported if those fields contain solely numbers. <a href="https://docs.dayback.com/article/139-analytics#data" target="_blank">Learn More</a>',
			},
			showMeasureSettings: {
				setting: 'showMeasureSettings',
				displayValue: 'Show Analytics Settings By Default',
				saved: 'showMeasureSettings',
				defaultValue: true,
				visible: true,
				format: 'yesno',
				category: 'Analytics',
				helptext:
					'Show chart settings by default when viewing a chart.',
			},
			measureNumberFormat: {
				setting: 'measureNumberFormat',
				displayValue: 'Number Format',
				saved: 'measureNumberFormat',
				defaultValue: 'number',
				visible: true,
				format: 'select',
				options: [
					{id: 'number', label: 'Number'},
					{id: 'percent', label: 'Percent'},
				],
				category: 'Analytics',
				helptext: 'The format to display number data as.',
			},
			measureNumberLabelBefore: {
				setting: 'measureNumberLabelBefore',
				displayValue: 'Label Before Value',
				saved: 'measureNumberLabelBefore',
				defaultValue: '',
				allowEmpty: true,
				visible: true,
				category: 'Analytics',
				preventTrim: true,
				helptext:
					'A text label to accompany number values. Appears before value.',
			},
			measureNumberLabelAfter: {
				setting: 'measureNumberLabelAfter',
				displayValue: 'Label After Value',
				saved: 'measureNumberLabelAfter',
				defaultValue: '',
				allowEmpty: true,
				visible: true,
				preventTrim: true,
				category: 'Analytics',
				helptext:
					'A text label to accompany number values. Appears after value.',
			},
			measureDecimalSymbol: {
				setting: 'measureDecimalSymbol',
				displayValue: 'Decimal Symbol',
				saved: 'measureDecimalSymbol',
				defaultValue: '.',
				visible: true,
				category: 'Analytics',
				helptext: 'The symbol used for a decimal.',
			},
			measureDecimalPlaces: {
				setting: 'measureDecimalPlaces',
				displayValue: 'Decimal Places',
				saved: 'measureDecimalPlaces',
				defaultValue: '',
				allowEmpty: true,
				visible: true,
				category: 'Analytics',
				helptext: 'Maximum amount of decimal places for a number.',
			},
			measureThousandsSeparator: {
				setting: 'measureThousandsSeparator',
				displayValue: 'Thousands Separator',
				saved: 'measureThousandsSeparator',
				defaultValue: ',',
				allowEmpty: true,
				visible: true,
				category: 'Analytics',
				helptext: 'The character used as a thousands separator.',
			},
			returnSub: {
				setting: 'returnSub',
				displayValue: 'Return character substitution',
				defaultValue: ' | ',
				visible: true,
				category: 'Event Styles',
				preventTrim: true,
				helptext:
					"In order to show more information about an event at once, DayBack will swap carriage returns in the event's title and description for this character unti the event is clicked on.",
			},
			firstDay: {
				setting: 'firstDay',
				displayValue: 'First day of week',
				defaultValue: 0,
				visible: true,
				category: 'Date / Time Formats',
				helptext:
					'Change this value to start the week on Monday (1) or any other day:' +
					'<table class="exampleCode">' +
					'<tr><td>0</td><td translate>Sunday</td></tr>' +
					'<tr><td>1</td><td translate>Monday</td></tr>' +
					'<tr><td>2</td><td translate>Tuesday</td></tr>' +
					'<tr><td>3</td><td translate>Wednesday</td></tr>' +
					'<tr><td>4</td><td translate>Thursday</td></tr>' +
					'<tr><td>5</td><td translate>Friday</td></tr>' +
					'<tr><td>6</td><td translate>Saturday</td></tr>' +
					'</table><br>',
			},
			minDate: {
				setting: 'minDate',
				displayValue: 'Earliest date visible',
				saved: 'minDate',
				defaultValue: '1582-10-15',
				visible: false,
				category: 'Date / Time Formats',
				helptext:
					'The earliest date you can navigate to on the calendar.',
			},
			fiscalYearStarts: {
				setting: 'fiscalYearStarts',
				displayValue: 'Fiscal year starts',
				saved: 'fiscalYearStarts',
				defaultValue: 1,
				visible: true,
				category: 'Date / Time Formats',
				format: 'select',
				options: [
					{id: 1, label: 'January'},
					{id: 2, label: 'February'},
					{id: 3, label: 'March'},
					{id: 4, label: 'April'},
					{id: 5, label: 'May'},
					{id: 6, label: 'June'},
					{id: 7, label: 'July'},
					{id: 8, label: 'August'},
					{id: 9, label: 'September'},
					{id: 10, label: 'October'},
					{id: 11, label: 'November'},
					{id: 12, label: 'December'},
				],
				helptext: 'The month that the first quarter should start in.',
			},
			weekNumbers: {
				setting: 'weekNumbers',
				displayValue: 'Show week numbers',
				defaultValue: false,
				visible: true,
				format: 'yesno',
				category: 'Date / Time Formats',
			},
			allDaySlot: {
				setting: 'allDaySlot',
				displayValue: 'Show all day slot',
				defaultValue: true,
				visible: true,
				format: 'yesno',
				category: 'Views',
				helptext:
					'When set to "No" the all day event area is not shown on schedule views.',
			},
			slotEventOverlap: {
				setting: 'slotEventOverlap',
				displayValue: 'Show events overlapping',
				defaultValue: true,
				visible: true,
				format: 'yesno',
				category: 'Views',
				helptext:
					'When set to "Yes" overlapping events will be drawn with portions of the top event covering the bottom event. When "No" overlapping events will be drawn beside each other with no one event covering part of another.',
			},
			slotDuration: {
				setting: 'slotDuration',
				displayValue: 'Time interval',
				saved: 'slotDuration',
				defaultValue: '00:15:00',
				visible: true,
				format: 'select',
				options: timeDurations,
				category: 'Time Scales',
				helptext:
					'The time increment down the left side of the calendar on the "schedule" views. Almost any increment can be used but the following look best:' +
					'<table class="exampleCode">' +
					'<tr><td>00:05:00</td><td>5 minutes</td></tr>' +
					'<tr><td>00:10:00</td><td>10 min</td></tr>' +
					'<tr><td>00:15:00</td><td>15 min</td></tr>' +
					'<tr><td>00:20:00</td><td>20 min</td></tr>' +
					'<tr><td>00:30:00</td><td>30 min</td></tr>' +
					'<tr><td>00:60:00</td><td>60 min</td></tr>' +
					'</table><br>',
			},
			scrollTime: {
				setting: 'scrollTime',
				displayValue: 'Scroll to time by default',
				saved: 'scrollTime',
				defaultValue: '06:00:00',
				visible: true,
				category: 'Time Scales',
				format: 'time',
				helptext:
					'When you first arrive at one of the "schedule" views, this will be the first time shown, though you can scroll earlier and later in the day.',
			},
			minTime: {
				setting: 'minTime',
				displayValue: 'Earliest time visible',
				saved: 'minTime',
				defaultValue: '00:00:00',
				visible: true,
				category: 'Time Scales',
				format: 'time',
				helptext: 'The earliest time you can scroll to in the day.',
			},
			maxTime: {
				setting: 'maxTime',
				displayValue: 'Latest time visible',
				saved: 'maxTime',
				defaultValue: '24:00:00',
				visible: true,
				category: 'Time Scales',
				format: 'time',
				helptext: 'The latest time you can scroll to in the day.',
			},
			nextDayThreshold: {
				setting: 'nextDayThreshold',
				displayValue: 'Next day threshold',
				defaultValue: '09:00:00',
				visible: true,
				category: 'Time Scales',
				format: 'time',
				helptext:
					'This is the earliest time that an event needs to cross to be considered a two day timed event on non schedule views. For example looking at timed events in month view if the event does not end after 9:00 the next day it will only show as a one day event.',
			},
			gridTimeColumns: {
				setting: 'gridTimeColumns',
				displayValue: 'Number of time columns in pivot view',
				saved: 'gridTimeColumns',
				defaultValue: '18',
				visible: true,
				category: 'Time Scales',
				helptext:
					'The amount of time columns displayed in the pivot view.',
			},
			editTimeDuration: {
				setting: 'editTimeDuration',
				displayValue: 'Time increment when editing',
				saved: 'editTimeDuration',
				defaultValue: 'auto',
				visible: true,
				category: 'Time Scales',
				format: 'select',
				options: [{id: 'auto', label: 'Auto'}, ...timeDurations],
				helptext:
					'The time increments available when selecting a time as you\'re editing an event. Set this to "auto" to use whatever you\'re using for "Time Scales / Time interval" (the increment used on the left side of the "schedule" views). Otherwise, almost any increment can be used but the following work best:' +
					'<table class="exampleCode">' +
					'<tr><td>00:05:00</td><td>5 minutes</td></tr>' +
					'<tr><td>00:10:00</td><td>10 min</td></tr>' +
					'<tr><td>00:15:00</td><td>15 min</td></tr>' +
					'<tr><td>00:20:00</td><td>20 min</td></tr>' +
					'<tr><td>00:30:00</td><td>30 min</td></tr>' +
					'<tr><td>00:60:00</td><td>60 min</td></tr>' +
					'</table><br>',
			},
			maxAllDayEvents: {
				setting: 'maxAllDayEvents',
				displayValue: 'Maximum all day rows to show',
				defaultValue: 9,
				visible: true,
				category: 'Views',
				helptext:
					'The number of all day events DayBack should display at the top of the "schedule" views before using "X more" to indicate additional all-day events on that day.',
			},
			resourceColumns: {
				setting: 'resourceColumns',
				displayValue: '<span translate>Resource</span> columns to show',
				saved: 'resourceColumns',
				defaultValue: 3,
				visible: true,
				views: [
					'basicResourceVert',
					'agendaResourceVert',
					'basicResourceHor',
					'agendaResourceHor',
					'basicResourceDays',
				],
				category: 'Views',
				helptext:
					'The number of vertical columns showing by default on the <span translate>Resource</span> tab.',
			},
			resourceDays: {
				setting: 'resourceDays',
				displayValue:
					'Days to show on <span translate>resource</span> view',
				saved: 'resourceDays',
				defaultValue: 4,
				visible: true,
				views: [
					'basicResourceVert',
					'agendaResourceVert',
					'basicResourceHor',
					'agendaResourceHor',
					'basicResourceDays',
					'agendaDays',
				],
				category: 'Views',
				helptext:
					'The number of day columns by default on the <span translate>Resource</span> tab.',
			},
			resourcePosition: {
				setting: 'resourcePosition',
				displayValue: 'Resource Column Position',
				saved: 'resourcePosition',
				defaultValue: 0,
				visible: false,
				views: [
					'basicResourceVert',
					'agendaResourceVert',
					'basicResourceHor',
					'agendaResourceHor',
					'basicResourceDays',
				],
				category: 'Views',
				helptext:
					'The navigation position of <span translate>Resource</span> columns.',
			},
			weekCount: {
				setting: 'weekCount',
				displayValue:
					'Weeks to show on <span translate>week</span> view',
				saved: 'weekCount',
				defaultValue: 1,
				visible: true,
				views: ['agendaWeek', 'basicWeek'],
				category: 'Views',
				helptext:
					'The number of weeks by default on the <span translate>week</span> tab.',
			},
			horizonDays: {
				setting: 'horizonDays',
				displayValue:
					'Days to show on <span translate>horizon</span> view',
				saved: 'horizonDays',
				defaultValue: 0,
				visible: true,
				format: 'select',
				options: [
					{id: 7, label: '7 Days'},
					{id: 14, label: '14 Days'},
					{id: 21, label: '21 Days'},
					{id: 30, label: '30 Days'},
					{id: 45, label: '45 Days'},
					{id: 60, label: '60 Days'},
					{id: 75, label: '75 Days'},
					{id: 90, label: '90 Days'},
				],
				category: 'Views',
				helptext:
					'The number of day columns showing by default on the <span translate>Horizon</span> tab.',
			},
			horizonSlider: {
				setting: 'horizonSlider',
				displayValue:
					'Days to show on <span translate>horizon</span> view',
				saved: 'horizonSlider',
				defaultValue: 28,
				visible: false,
				category: 'Views',
				helptext:
					'The number of day columns showing by default on the <span translate>Horizon</span> tab.',
			},
			noFilterLabel: {
				setting: 'noFilterLabel',
				displayValue: 'No filter label',
				defaultValue: 'none',
				visible: true,
				category: 'Misc',
				helptext:
					'If an event has no <span translate>Resource</span> or <span translate>Status</span> associated with it, this is the label for the filter that will show those events. Other options here might be "Unassigned", "TBD", "NA", etc.',
			},
			defaultSidebarTab: {
				setting: 'defaultSidebarTab',
				displayValue: 'Default Sidebar Tab',
				defaultValue: 'calendars',
				visible: true,
				format: 'select',
				options: [
					{id: 'calendars', label: 'Calendars'},
					{id: 'filters', label: 'Filters'},
					{id: 'settings', label: 'Settings'},
					{id: 'mini-calendars', label: 'Mini Calendars'},
				],
				category: 'Views',
				helptext:
					'Please select the name of the tab you would like the sidebar to default.',
			},
			view: {
				setting: 'view',
				displayValue: 'Default View',
				defaultValue: 'basicHorizon',
				visible: true,
				format: 'select',
				options: [
					{id: 'basicDay', label: 'Day List'},
					{id: 'agendaDay', label: 'Day Schedule'},
					{id: 'basicWeek', label: 'Week List'},
					{id: 'agendaWeek', label: 'Week Schedule'},
					{id: 'month', label: 'Month List'},
					{id: 'agendaDays', label: 'Month Schedule'},
					{id: 'basicHorizon', label: 'Horizon'},
					{id: 'basicResourceDays', label: 'Resource Daily'},
					{id: 'basicResourceVert', label: 'Resource List'},
					{id: 'agendaResourceVert', label: 'Resource Schedule'},
					{id: 'basicResourceHor', label: 'Pivot List'},
					{id: 'agendaResourceHor', label: 'Pivot Schedule'},
				],
				category: 'Views',
				helptext:
					'Select the default calendar view. This is the view the calendar will load the first time it is loaded.',
			},
			hideMenuItems: {
				setting: 'hideMenuItems',
				displayValue: 'Hide Menu Items',
				defaultValue: '',
				visible: true,
				category: 'Views',
				helptext:
					'Please enter a comma-separated list of menu items to hide. Options are Home, View, Day, Day Schedule, Day List, Week, Week Schedule, Week List, Month, Month Schedule, Month List, Horizon, Horizon Breakout by Resouce, Horizon Breakout by Status, Horizon Breakout by Calendar, Horizon Breakout More, Resource, Resource Schedule, Resource List, Pivot Schedule, Pivot List, Share, Notifications, More.<br /><br />Remove the whole 3-dots menu in the upper right by adding "notifications". To remove items within the 3-dots menu, add "contact us", "documentation", "admin settings", "schedule a demo".<br /><br />Remove items from the Bookmark/Share menu with "share this view", "manage shares", "recent bookmarks", "enable sharing"',
				mutate: function (data) {
					var result = [];
					if (!data) {
						return;
					}
					//Check if we are already an array or not
					if (!Array.isArray(data)) {
						data = data.toLowerCase();
						data = data.split(',');
					}
					for (var i = 0; i < data.length; i++) {
						result.push(data[i].trim());
					}
					return result;
				},
			},
			homeUrl: {
				setting: 'homeUrl',
				displayValue: 'Home Button URL',
				defaultValue: '',
				visible: true,
				category: 'Views',
				helptext:
					'Please enter a URL you would like to visit when clicking the home button in the calendar navigation. To hide the home button leave this blank.',
			},
			language: {
				setting: 'language',
				displayValue: 'Language',
				defaultValue: 'auto',
				visible: true,
				format: 'select',
				options: [
					{id: 'auto', label: 'auto'},
					{id: 'en', label: 'English'},
					{id: 'es', label: 'español'},
					{id: 'fr', label: 'français'},
					{id: 'de', label: 'Deutsch'},
					{id: 'it', label: 'italiano'},
					{id: 'da', label: 'dansk'},
					{id: 'nl', label: 'Nederlands'},
					{id: 'sv', label: 'svenska'},
					{id: 'ja', label: '日本人'},
				],
				category: 'misc',
				helptext:
					'Language for the calendar. Use auto for the language to be set based on your computer language.',
			},
			locale: {
				setting: 'locale',
				displayValue: 'Locale',
				defaultValue: 'auto',
				visible: true,
				format: 'select',
				options: [
					{id: 'auto', label: 'auto'},
					{id: 'af', label: 'Afrikaans'},
					{id: 'sq', label: 'Albanian'},
					{id: 'ar', label: 'Arabic'},
					{id: 'ar-dz', label: 'Arabic (Algeria)'},
					{id: 'ar-kw', label: 'Arabic (Kuwait)'},
					{id: 'ar-ly', label: 'Arabic (Libya)'},
					{id: 'ar-ma', label: 'Arabic (Morocco)'},
					{id: 'ar-sa', label: 'Arabic (Saudi Arabia)'},
					{id: 'ar-tn', label: 'Arabic (Tunisia)'},
					{id: 'hy-am', label: 'Armenian (Armenia)'},
					{id: 'az', label: 'Azerbaijani'},
					{id: 'bm', label: 'Bambara'},
					{id: 'eu', label: 'Basque'},
					{id: 'be', label: 'Belarusian'},
					{id: 'bn', label: 'Bengali'},
					{id: 'bn-bd', label: 'Bengali (Bangladesh)'},
					{id: 'bs', label: 'Bosnian'},
					{id: 'br', label: 'Breton'},
					{id: 'bg', label: 'Bulgarian'},
					{id: 'my', label: 'Burmese'},
					{id: 'ca', label: 'Catalan'},
					{id: 'tzm', label: 'Central Atlas Tamazight'},
					{id: 'tzm-latn', label: 'Central Atlas Tamazight (Latin)'},
					{id: 'zh-cn', label: 'Chinese (China)'},
					{id: 'zh-hk', label: 'Chinese (Hong Kong)'},
					{id: 'zh-mo', label: 'Chinese (Macau)'},
					{id: 'zh-tw', label: 'Chinese (Taiwan)'},
					{id: 'cv', label: 'Chuvash'},
					{id: 'hr', label: 'Croatian'},
					{id: 'cs', label: 'Czech'},
					{id: 'da', label: 'Danish'},
					{id: 'dv', label: 'Divehi '},
					{id: 'nl', label: 'Dutch'},
					{id: 'nl-be', label: 'Dutch (Belgium)'},
					{id: 'en-au', label: 'English (Australia)'},
					{id: 'en-ca', label: 'English (Canada)'},
					{id: 'en-in', label: 'English (India)'},
					{id: 'en-ie', label: 'English (Ireland)'},
					{id: 'en-il', label: 'English (Israel)'},
					{id: 'en-nz', label: 'English (New Zealand)'},
					{id: 'en-sg', label: 'English (Singapore)'},
					{id: 'en-gb', label: 'English (United Kingdom)'},
					{id: 'en', label: 'English (United States)'},
					{id: 'eo', label: 'Esperanto'},
					{id: 'et', label: 'Estonian'},
					{id: 'fo', label: 'Faroese'},
					{id: 'fil', label: 'Filipino'},
					{id: 'fi', label: 'Finnish'},
					{id: 'fr', label: 'French'},
					{id: 'fr-ca', label: 'French (Canada)'},
					{id: 'fr-ch', label: 'French (Switzerland)'},
					{id: 'gl', label: 'Galician'},
					{id: 'ka', label: 'Georgian'},
					{id: 'de', label: 'German'},
					{id: 'de-at', label: 'German (Austria)'},
					{id: 'de-ch', label: 'German (Switzerland)'},
					{id: 'gom-latn', label: 'gom (Latin)'},
					{id: 'el', label: 'Greek'},
					{id: 'gu', label: 'Gujarati'},
					{id: 'he', label: 'Hebrew'},
					{id: 'hi', label: 'Hindi'},
					{id: 'hu', label: 'Hungarian'},
					{id: 'is', label: 'Icelandic'},
					{id: 'id', label: 'Indonesian'},
					{id: 'it', label: 'Italian '},
					{id: 'it-ch', label: 'Italian (Switzerland)'},
					{id: 'ja', label: 'Japanese'},
					{id: 'jv', label: 'Javanese'},
					{id: 'kn', label: 'Kannada'},
					{id: 'kk', label: 'Kazakh'},
					{id: 'km', label: 'Khmer'},
					{id: 'ky', label: 'Kirghiz'},
					{id: 'tlh', label: 'Klingon'},
					{id: 'ko', label: 'Korean'},
					{id: 'ku', label: 'Kurdish'},
					{id: 'lo', label: 'Lao'},
					{id: 'lv', label: 'Latvian'},
					{id: 'lt', label: 'Lithuanian'},
					{id: 'lb', label: 'Luxembourgish'},
					{id: 'mk', label: 'Macedonian'},
					{id: 'ms', label: 'Malay'},
					{id: 'ms-my', label: 'Malay (Malaysia)'},
					{id: 'ml', label: 'Malayalam'},
					{id: 'mt', label: 'Maltese'},
					{id: 'mi', label: 'Maori'},
					{id: 'mr', label: 'Marathi'},
					{id: 'mn', label: 'Mongolian'},
					{id: 'me', label: 'Montenegrin'},
					{id: 'ne', label: 'Nepali'},
					{id: 'se', label: 'Northern Sami'},
					{id: 'nb', label: 'Norwegian Bokmål'},
					{id: 'nn', label: 'Norwegian Nynorsk'},
					{id: 'fa', label: 'Persian'},
					{id: 'pl', label: 'Polish'},
					{id: 'pt', label: 'Portuguese'},
					{id: 'pt-br', label: 'Portuguese (Brazil)'},
					{id: 'x-pseudo', label: 'Pseudo'},
					{id: 'pa-in', label: 'Punjabi (India)'},
					{id: 'ro', label: 'Romanian'},
					{id: 'ru', label: 'Russian'},
					{id: 'gd', label: 'Scottish Gaelic'},
					{id: 'sr', label: 'Serbian'},
					{id: 'sr-cyrl', label: 'Serbian (Cyrillic)'},
					{id: 'sd', label: 'Sindhi'},
					{id: 'si', label: 'Sinhala'},
					{id: 'sk', label: 'Slovak'},
					{id: 'sl', label: 'Slovenian'},
					{id: 'es', label: 'Spanish'},
					{id: 'es-do', label: 'Spanish (Dominican Republic)'},
					{id: 'es-mx', label: 'Spanish (Mexico) '},
					{id: 'es-us', label: 'Spanish (United States)'},
					{id: 'sw', label: 'Swahili'},
					{id: 'ss', label: 'Swati'},
					{id: 'sv', label: 'Swedish'},
					{id: 'tl-ph', label: 'Tagalog (Philippines)'},
					{id: 'tg', label: 'Tajik'},
					{id: 'tzl', label: 'Talossan'},
					{id: 'ta', label: 'Tamil'},
					{id: 'te', label: 'Telugu'},
					{id: 'tet', label: 'Tetum'},
					{id: 'th', label: 'Thai'},
					{id: 'bo', label: 'Tibetan'},
					{id: 'tr', label: 'Turkish'},
					{id: 'tk', label: 'Turkmen'},
					{id: 'uk', label: 'Ukrainian'},
					{id: 'ur', label: 'Urdu'},
					{id: 'ug-cn', label: 'Uyghur (China)'},
					{id: 'uz', label: 'Uzbek'},
					{id: 'uz-latn', label: 'Uzbek (Latin)'},
					{id: 'vi', label: 'Vietnamese'},
					{id: 'cy', label: 'Welsh'},
					{id: 'fy', label: 'Western Frisian'},
					{id: 'yo', label: 'Yoruba (Nigeria)'},
				],
				category: 'misc',
				helptext:
					'Locale for the calendar. Use auto for the locale to be set based on your computer or browser region. This setting will affect date formats in some places in DayBack.',
			},
			sharePrivileges: {
				setting: 'sharePrivileges',
				displayValue: 'Allowed To Share',
				defaultValue: 'admin',
				visible: true,
				format: 'select',
				options: [
					{id: 'off', label: 'Off'},
					{id: 'admin', label: 'Administrators'},
					{id: 'everyone', label: 'Everyone'},
				],
				category: 'misc',
				helptext:
					'Which type of users can share as read only events with others that do not have access to the calendar.',
			},
			undoTimeout: {
				setting: 'undoTimeout',
				displayValue: 'Undo timeout in seconds',
				defaultValue: 5,
				visible: true,
				category: 'misc',
				helptext:
					'The number of seconds to display a successful save confirmation and undo changes button.',
				mutate: function (data) {
					//Normalize data
					if (data) {
						return data * 1000;
					} else {
						return 5000;
					}
				},
			},
			adminAccountList: {
				setting: 'adminAccountList',
				displayValue: 'Additional Admin Accounts',
				defaultValue: '',
				visible: true,
				platform: 'dbksf',
				category: 'misc',
				helptext:
					'A comma separated list of user email addresses that can administer DayBack even if they are not System Admins in their Salesforce profile.',
				mutate: function (data) {
					var result = [];
					if (!data) {
						return;
					}
					//Check if we are already an array or not
					if (!Array.isArray(data)) {
						data = data.toLowerCase();
						data = data.split(',');
					}
					for (var i = 0; i < data.length; i++) {
						result.push(data[i].trim());
					}
					return result;
				},
			},
			showRemoteNotifications: {
				setting: 'showRemoteNotifications',
				displayValue: "Show what's new for",
				defaultValue: 'everyone',
				visible: true,
				category: 'misc',
				format: 'select',
				options: [
					{id: 'off', label: 'Off'},
					{id: 'admin', label: 'Administrator'},
					{id: 'everyone', label: 'Everyone'},
				],
				helptext:
					"Which type of users can see what's new in the notifications menu.",
			},
			showInTimezone: {
				setting: 'showInTimezone',
				displayValue: 'Show In Timezone',
				defaultValue: false,
				visible: false,
				format: 'yesno',
				category: 'misc',
				helptext:
					'If set to yes there will be a timezone selector available to change the timezone events are shown in.',
			},
			timezonesAvailable: {
				setting: 'timezonesAvailable',
				displayValue: 'Available timezones',
				defaultValue: false,
				visible: false,
				format: 'yesno',
				category: 'misc',
				helptext:
					"A comma seperated list of timezones available for selection. Timezones should be in a standard momentjs timezone format that looks like 'America/Los_Angeles'",
			},
			clientTimezone: {
				setting: 'clientTimezone',
				displayValue: 'Timezone',
				defaultValue: '',
				saved: 'clientTimezone',
				visible: false,
				category: 'misc',
				format: 'select',
				options: (function () {
					var option;
					var result = [
						{
							id: '',
							label: 'Auto',
						},
					];
					var zones = moment.tz.names();
					if (!zones || !Array.isArray(zones)) {
						return result;
					}

					for (var i = 0; i < zones.length; i++) {
						option = {};
						option.id = zones[i];
						option.label = zones[i];
						result.push(option);
					}

					return result;
				})(),
				helptext:
					'The calendar will render events as if it was loaded in the specified timezone.',
			},
			darkMode: {
				setting: 'darkMode',
				displayValue: 'Dark Mode',
				saved: 'darkMode',
				defaultValue: false,
				visible: false,
				format: 'yesno',
				category: 'Misc',
				helptext:
					'This option will change the theme of DayBack so it displays in dark mode. This will better match a device currently set in dark mode.',
			},
			cssTheme: {
				setting: 'cssTheme',
				displayValue: 'Theme',
				saved: 'cssTheme',
				defaultValue: '',
				visible: true,
				format: 'select',
				options: [
					{id: '', label: 'DayBack'},
					{id: 'darkMode', label: 'Dark Mode'},
					{id: 'darkModeAuto', label: 'Dark Mode Auto'},
				],
				category: 'Misc',
				helptext:
					'This option will change the theme of DayBack. Dark Mode Auto will automatically use your device setting to determine the Dark Mode theme.',
			},
			activeSessionLimit: {
				setting: 'activeSessionLimit',
				displayValue: 'Device limit',
				saved: 'activeSessionLimit',
				defaultValue: '3',
				visible: false,
				category: 'misc',
				helptext:
					'The number of devices that can be signed in simultaneously under the same account.',
			},
			doNotTrack: {
				setting: 'doNotTrack',
				displayValue: 'Do Not Track',
				saved: 'doNotTrack',
				defaultValue: false,
				visible: false,
				format: 'yesno',
				category: 'Misc',
				helptext:
					'This option will prevent any information from being sent to analytics. Analytics helps improve DayBack and monitor performance issues.',
			},
			isVertical: {
				setting: 'isVertical',
				displayValue: 'Is Vertical License',
				saved: 'isVertical',
				defaultValue: false,
				visible: false,
				format: 'yesno',
				category: 'Misc',
				helptext:
					'This should be set to true if this is a client that purchased a vertical license.',
			},
			manageUserCount: {
				setting: 'manageUserCount',
				displayValue: 'Manage User Count',
				saved: 'manageUserCount',
				defaultValue: false,
				visible: false,
				format: 'yesno',
				category: 'Misc',
				helptext:
					'When turned on any changes to active members will be automatically reflected in billing.',
			},
			useLocalSettings: {
				setting: 'useLocalSettings',
				displayValue: 'Use Local Settings',
				defaultValue: false,
				visible: false,
				format: 'yesno',
				category: 'Misc',
				helptext:
					'When turned on settings will be read and stored in local storage or cookies and will be device specific.',
			},
			resourceListFilter: {
				setting: 'resourceListFilter',
				displayValue: 'Resource List Filter',
				saved: 'resourceListFilter',
				defaultValue: '',
				visible: false,
				category: 'Misc',
				helptext: 'The contents of the resource list text filter.',
			},
			breakoutTooltips: {
				setting: 'breakoutTooltips',
				displayValue: 'Resource Tooltips',
				saved: 'breakoutTooltips',
				defaultValue: 'name',
				visible: true,
				format: 'select',
				options: [
					{id: 'detailed', label: 'Detailed'},
					{id: 'name', label: 'Name Only'},
					{id: 'none', label: 'None'},
				],
				category: 'Views',
				helptext:
					'"Detailed" will show all resource details in a tooltip, "Name Only" will show the full resource name in scenarios when the short name is showing. "None" will prevent a tooltip from showing.',
			},
			unscheduledEnabled: {
				setting: 'unscheduledEnabled',
				displayValue: 'Enable Unscheduled',
				saved: 'unscheduledEnabled',
				defaultValue: false,
				visible: true,
				format: 'yesno',
				category: 'Views',
				helptext:
					'Wether or not to show the unscheduled events in a side pane.',
			},
			unscheduledSort: {
				setting: 'unscheduledSort',
				displayValue: 'Sort Unscheduled By',
				saved: 'unscheduledSort',
				defaultValue: 'creationDesc',
				visible: true,
				format: 'select',
				options: [
					{id: 'creationAsc', label: 'Creation Ascending'},
					{id: 'creationDesc', label: 'Creation Descending'},
					{id: 'dateAsc', label: 'Start Date Ascending'},
					{id: 'dateDesc', label: 'Start Date Descending'},
				],
				category: 'Views',
				helptext:
					'Wether or not to show the unscheduled events in a side pane.',
			},
			unscheduledFilter: {
				setting: 'unscheduledFilter',
				displayValue: 'Unscheduled Filter',
				saved: 'unscheduledFilter',
				defaultValue: '',
				visible: false,
				category: 'Misc',
				helptext: 'The contents of the unscheduled list text filter.',
			},
			bookmarkFilter: {
				setting: 'bookmarkFilter',
				displayValue: 'Bookmark Filter',
				saved: 'bookmarkFilter',
				defaultValue: '',
				visible: false,
				category: 'Misc',
				helptext: 'The contents of the bookmark list text filter',
			},
			mapActivated: {
				setting: 'mapActivated',
				displayValue: 'Activate Map',
				saved: 'mapActivated',
				defaultValue: false,
				visible: false,
				format: 'yesno',
				category: 'Views',
				helptext:
					'The map will not be available unless this is set. This is an internal boolean mean to be set under the hood to activate the map.',
			},
			mapEnabled: {
				setting: 'mapEnabled',
				displayValue: 'Enable Map',
				saved: 'mapEnabled',
				defaultValue: false,
				visible: (settings) => {
					return settings.mapActivated;
				},
				format: 'yesno',
				category: 'Views',
				helptext:
					'If set to yes will enable map functionality in the right side view. <a href="https://docs.dayback.com/category/322-maps-drive-times" target="_blank">Learn More</a>',
			},
			mapApiKey: {
				setting: 'mapApiKey',
				displayValue: 'Map API Key',
				saved: 'mapMapiKey',
				defaultValue: '',
				visible: (settings) => {
					return settings.mapActivated;
				},
				category: 'Views',
				helptext:
					'The Google Map API key to use for mapping requests. <a href="https://docs.dayback.com/article/324-creating-a-maps-api-key" target="_blank">Learn More</a>',
			},
			altViewType: {
				setting: 'altViewType',
				displayValue: 'Default Side View Tab',
				saved: 'altViewType',
				defaultValue: 'unscheduled',
				visible: (settings) => {
					return settings.mapActivated;
				},
				format: 'select',
				options: [
					{id: 'unscheduled', label: 'Unscheduled'},
					{id: 'map', label: 'Map'},
				],
				category: 'Views',
				helptext: 'What to display in the right side view by default.',
			},
			showAltView: {
				setting: 'showAltView',
				displayValue: 'Default Sidebar View Open',
				saved: 'showAltView',
				defaultValue: false,
				visible: true,
				format: 'yesno',
				category: 'Views',
				helptext:
					'Wether or not to show the unscheduled and map sections in a side pane by default.',
			},
			altViewWidth: {
				setting: 'altViewWidth',
				displayValue: 'Unscheduled Size',
				saved: 'altViewWidth',
				defaultValue: 1,
				visible: false,
				format: 'select',
				options: [{id: 260, label: 'Small'}],
				category: 'Views',
				helptext: 'The size of the window pane that unscheduled is in.',
			},
		};

		//Remove this defaults object once we figure out what to do with objects
		calendar.defaults = {
			defaultDate: moment(),
			dateStringFormat: 'dddd, MMM D, YYYY',
			dateStringShortFormat: 'ddd, MMM D, YYYY',

			titleFormat: {
				year: 'YYYY', // 2020
				month: 'MMMM YYYY', // September 2014
				week: 'll', // Sept 7 - 14, 2014
				day: 'dddd, ll', // Tuesday, Sep 8, 2014
				// resource: 'dddd, MMM D, YYYY', // Tuesday, Sep 8 2014
				// horizon: 'dddd, MMM D, YYYY' // Tuesday, Sep 8 2014
			},
		};

		calendar.eventDictionary = {
			eventSource: 'eventSource',
			eventID: 'eventID',
			allDay: 'allDay',
			start: 'start',
			end: 'end',
			title: 'title',
			titleEdit: 'titleEdit',
			description: 'description',
			resource: 'resource',
			status: 'status',
			contactID: 'contactID',
			contactName: 'contactName',
			projectID: 'projectID',
			projectName: 'projectName',
			location: 'location',
			geocode: 'geocode',
			tags: 'tags',
			isDefaultResource: 'isDefaultResource',
			timezone: 'timezone',
			recurringEventID: 'recurringEventID',
			recurrence: 'recurrence',
			eventURL: 'eventURL',
			unscheduled: 'unscheduled',
			todo: 'todo',
			parentListID: 'parentListID',
			parentListName: 'parentListName',
			done: 'done',
			hasAttachments: 'hasAttachments',
			hasImages: 'hasImages',
			attachmentsURL: 'attachmentsURL',
			featuredImage: 'featuredImage',
			featuredImageClass: 'featuredImageClass',
			featuredImageThumbnail: 'featuredImageThumbnail',
			featuredImageThumbnailClass: 'featuredImageThumbnailClass',
		};

		calendar.defaultLabelMap = {
			allDay: 'All Day',
			start: 'Start',
			end: 'End',
			title: 'Title',
			titleEdit: 'Title',
			description: 'Description',
			resource: 'Resource',
			status: 'Status',
			contactName: 'Contact',
			projectName: 'Project',
			location: 'Location',
			tags: 'Tags',
			timezone: 'Timezone',
			recurrence: 'Recurrence',
			eventURL: 'Event URL',
			todo: 'ToDo',
			parentListName: 'parentListName',
			done: 'Done',
		};

		calendar.calendarActionTypes = [
			{id: 'startup', label: 'On Startup'},
			{id: 'sourcesFetched', label: 'On Sources Fetched'},
			{id: 'schedulesFetched', label: 'On Calendars Fetched'},
			{id: 'resourcesFetched', label: 'On Resources Fetched'},
			{id: 'statusesFetched', label: 'On Statuses Fetched'},
			{id: 'projectsFetched', label: 'On Projects Fetched'},
			{id: 'contactsFetched', label: 'On Contacts Fetched'},
			{id: 'beforeCalendarRendered', label: 'Before Calendar Rendered'},
			{id: 'afterCalendarRendered', label: 'After Calendar Rendered'},
			{id: 'afterEventsRendered', label: 'After Events Rendered'},
			{id: 'afterViewChanged', label: 'After View Changed'},
			{id: 'afterSourceSelection', label: 'After Source Selection'},
			{id: 'afterFilterSelection', label: 'After Filter Selection'},
			{
				id: 'draftModeToggle',
				label: 'After Draft Mode Toggle',
			},
		];
		calendar.eventActionTypes = [
			{id: 'beforeEventsFetched', label: 'Before Events Fetched'},
			{id: 'eventCreate', label: 'On Event Create'},
			{id: 'eventClick', label: 'On Event Click'},
			{id: 'eventHover', label: 'On Event Hover'},
			{id: 'beforeEventRendered', label: 'Before Event Rendered'},
			{id: 'beforeEventSave', label: 'Before Event Save'},
			{id: 'eventSave', label: 'On Event Save'},
			{id: 'eventDelete', label: 'On Event Delete'},
			{id: 'onFieldChange', label: 'On Field Change'},
		];

		return {
			/* DEBUG */
			dev: dev,
			/* END DEBUG */
			calendar: calendar,
			init: init,
			get: get,
			set: set,
			reset: reset,
		};

		//Get the object
		function get(item) {
			return calendar[item];
		}
		//Initialize the data (used to set the object in one shot)
		function init(item, calendarData, preventBroadcast) {
			calendar[item] = calendarData;

			if (!preventBroadcast) {
				$rootScope.$broadcast(item, calendarData);
			}

			return calendar[item];
		}
		//Update specific object properties
		function set(item, key, value) {
			if (key) {
				calendar[item][key] = value;
			} else {
				calendar[item] = value;
			}
			return calendar[item];
		}

		function reset() {
			for (var property in calendar) {
				if (
					property !== 'altViewMethods' &&
					property !== 'eventActionTypes' &&
					property !== 'calendarActionTypes' &&
					property !== 'defaultLabelMap' &&
					property !== 'userPromises' &&
					property !== 'userConfigMap' &&
					property !== 'configMap' &&
					property !== 'defaults' &&
					property !== 'eventDictionary'
				) {
					calendar[property] = null;
				}
			}
			return calendar;
		}
	}
})();
